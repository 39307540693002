import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/img/avalio-logo-dark.svg';

import { CONTACT_EMAIL } from '../../../constants/misc';

import style from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={style.container}>
      <div className={style.contact}>
        <h4 className={style.title}>
          Want to know more?
          <br />
          Get in touch with us at{' '}
          <a
            href={`mailto:${CONTACT_EMAIL}`}
            className={style.mailLink}
          >
            {CONTACT_EMAIL}
          </a>
        </h4>
      </div>
      <div className={style.box}>
        <Link to={'/'}>
          <Logo className={style.logo} />
        </Link>
        <div className={style.list}>
          <p>Copyright © {new Date().getFullYear()} by Avalio</p>
          <Link
            to={'/privacy-policy'}
            className={style.link}
          >
            Privacy policy
          </Link>
          <Link
            to={'/statute'}
            className={style.link}
          >
            Terms of services
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
