import _ from 'lodash';

import { Toasts } from 'components';

export const apiErrorHandler = (error) => {
  if (!error.response?.data || error.message === 'canceled') {
    return [];
  }

  try {
    if (import.meta.env.VITE_NODE_ENV !== 'production') {
      return [`Server: ${error.response.data.message}, (status: ${error.response.status})`];
    } else {
      return [error.response.data.message];
    }
  } catch (e) {
    console.error('apiErrorHandler', e);
    return ['Wystąpił nieoczekiwany bląd'];
  }
};

export const isNetworkError = (err) => !!err.isAxiosError && !err.response;

const NETWORK_ERROR_NOTIFY_DURATION = 10000;

const notifyNetworkError = () => {
  Toasts.notifyDanger(['Coś poszło nie tak... Skontaktuj się z nami.'], { duration: NETWORK_ERROR_NOTIFY_DURATION });
};

export const throttledNotifyNetworkError = _.throttle(notifyNetworkError, NETWORK_ERROR_NOTIFY_DURATION, {
  trailing: false
});
