import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Footer, Header } from 'components';

import pages from 'src/dictionaries/pages.json';

import SeoHelmet from '../../components/layout/SeoHelmet';

import style from './NotFoundRoute.module.scss';

const NotFoundRoute = () => {
  const location = useLocation();

  return (
    <>
      <SeoHelmet title={pages.notFoundRoute.title} />
      <Header />
      <div className={style.container}>
        <h1 className={style.main}>404</h1>
        <h2 className={style.second}>{(location.state?.message || 'Coś poszło nie tak') + '. '}</h2>
        <h5>
          Wróć na <Link style={{ fontSize: 'inherit' }} to={'/'}>stronę główną.</Link>
        </h5>
      </div>
      <Footer />
    </>
  );
};

export default NotFoundRoute;
