import React, { useEffect, useRef } from 'react';
import cn from 'classnames';

import { ReactComponent as OctopusLogo } from 'assets/img/octopus-logo.svg';

import style from './TopSection.module.scss';

const TopSection = () => {
  const containerRef = useRef();

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrolled = window.scrollY;

      if (containerRef.current?.style?.backgroundPosition) {
        containerRef.current.style.backgroundPosition = 'bottom -' + scrolled * 0.1 + 'px';
      }
    });
  }, []);

  return (
    <div
      className={style.container}
      ref={containerRef}
    >
      <div className={style.box}>
        <h1 className={style.title}>The Future of Healthcare</h1>
        <p className={style.text}>
          At the heart of Avalio lies a simple yet powerful mission: <br />
          to empower healthcare organizations with seamless digital solutions that optimize their operations. <br/>
          We believe that efficient procurement processes translate to improved patient outcomes,
          and we&apos;re here to support medical facilities in their journey to deliver top-notch care.
        </p>
        <div className={style.row}>
          <p className={cn(style.text, style.strong)}>Supported by</p>
          <OctopusLogo className={style.logo} />
        </div>
      </div>
    </div>
  );
};

export default TopSection;
