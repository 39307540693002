import React from 'react';

import { ReactComponent as Cost } from 'assets/icons/cost.svg';
import { ReactComponent as Damand } from 'assets/icons/damand.svg';
import { ReactComponent as Financing } from 'assets/icons/financing.svg';
import { ReactComponent as RealTime } from 'assets/icons/real-time.svg';
import { ReactComponent as Streamlined } from 'assets/icons/streamlined.svg';
import { ReactComponent as Supplier } from 'assets/icons/supplier.svg';

import style from './WhyAvalio.module.scss';

const data = [
  {
    icon: Streamlined,
    title: 'Streamlined Purchasing Process',
    text: 'Avalio can automate and streamline the procurement process, from requisition to purchase order and invoice processing. This automation reduces the administrative burden on staff, freeing up their time for more strategic tasks'
  },
  {
    icon: Cost,
    title: 'Cost Optimization',
    text: 'Avalio helps to analyze historical purchasing data and identify cost-saving opportunities by suggesting alternative suppliers, negotiating better prices, and identifying bulk purchasing opportunities'
  },
  {
    icon: RealTime,
    title: 'Real-Time Inventory Management',
    text: 'Avalio can monitor inventory levels in real-time and trigger automatic reorder points. This ensures that essential supplies are always available'
  },
  {
    icon: Damand,
    title: 'Demand Forecasting',
    text: 'Avalio can predict the demand for different dental products based on historical data, seasonality, and other relevant factors which allows clinics to optimize their inventory levels that leads to cost savings'
  },
  {
    icon: Supplier,
    title: 'Supplier Management',
    text: 'Avalio can evaluate supplier performance based on various factors such as delivery times, product quality, and pricing accuracy which helps to make informed decisions and build more reliable and efficient supplier relationships'
  },
  {
    icon: Financing,
    title: 'Payments',
    text: 'Avalio allows you to track and execute all your expenses in one place. Process invoices and reduce errors'
  }
];

const WhyAvalio = () => {
  return (
    <div className={style.container}>
      <p className={style.subtitle}>Why Avalio</p>
      <h2 className={style.title}>Transparent and streamlined procurement starts with Avalio</h2>
      <div className={style.wrapper}>
        {data.map(({ title, text, icon: Icon }) => (
          <div
            className={style.card}
            key={title}
          >
            <Icon className={style.icon} />
            <h4 className={style.cardTitle}>{title}</h4>
            <p className={style.text}>{text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyAvalio;
