import React from 'react';

import { Button } from 'components';

import style from './TopSection.module.scss';

const TopSection = () => {
  return (
    <div className={style.container}>
      <div className={style.content}>
        <h1 className={style.title}>Smart procurement for the healthcare industry</h1>
        <p className={style.text}>
          Optimize costs and streamline processes through our AI-powered digital tools. Avalio helps the medical industry to
          provide exceptional patient care.
        </p>
        <Button
          label={'Schedule a Demo'}
          to={'/contact'}
        />
      </div>
    </div>
  );
};

export default TopSection;
