import React from 'react';
import Input from 'react-phone-number-input';
import classNames from 'classnames';

import InputWrapper, { getWrapperProps } from 'components/layout/InputWrapper';

import 'react-phone-number-input/style.css';
import style from './PhoneInput.module.scss';

const PhoneInput = (props) => {
  const { value, onChange, disabled, wrapperStyle, ...rest } = props;
  const wrapperProps = getWrapperProps(props);

  return (
    <InputWrapper {...wrapperProps}>
      <Input
        addInternationalOption={false}
        value={value}
        onChange={onChange}
        defaultCountry={'PL'}
        disabled={disabled}
        limitMaxLength
        className={classNames(style.phoneFormatter, {
          [style.error]: wrapperProps.hasError,
          [style.disabled]: disabled
        })}
        {...rest}
      />
    </InputWrapper>
  );
};

export default PhoneInput;
