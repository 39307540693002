import { Footer, Header } from 'components';

import SeoHelmet from '../SeoHelmet';

import style from './LegalTermWrapper.module.scss';

const LegalTermWrapper = ({ children, pageTitle, documentTitle }) => {
  return (
    <>
      <SeoHelmet
        title={pageTitle}
        description={pageTitle}
      />
      <Header transparent />
      <header className={style.topHeader}>
        <h1 className={style.title}>{documentTitle || pageTitle}</h1>
      </header>
      <div className={style.content}>
        <div className={style.wrapper}>{children}</div>
      </div>
      <Footer />
    </>
  );
};

export default LegalTermWrapper;
