import React from 'react';
import cn from 'classnames';

import { Button } from 'components';

import style from './ImageWithList.module.scss';

const ImageWithList = ({ section }) => {
  const { image, sources = [], imageAlt, subtitle, title, button, list = [], imageOnLeft, content = [], darkBg = false } = section;
  return (
    <section
      className={cn(style.container, { [style.darkBg]: darkBg })}
      key={title}
    >
      <picture className={cn(style.picture, { [style.imageOnLeft]: imageOnLeft })}>
        {sources.map(({ src, type, size }) => (
          <source srcSet={src + ' ' + size} type={type} key={src} />
        ))}
        <img
          src={image}
          alt={imageAlt}
          className={style.image}
        />
      </picture>
      <div className={style.wrapper}>
        <div className={style.header}>
          <p className={style.subtitle}>{subtitle}</p>
          <h3 className={style.title}>{title}</h3>
        </div>
        {list?.length > 0 && (
          <ul className={style.list}>
            {list.map((item) => (
              <li
                className={style.listItem}
                key={item}
              >
                {item}
              </li>
            ))}
          </ul>
        )}
        {content?.length > 0 && content.map((content, index) => <p key={index} className={style.text}>{content}</p>)}
        <Button
          label={button.label}
          to={button.link}
        />
      </div>
    </section>
  );
};

export default ImageWithList;
