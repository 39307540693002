import React from 'react';
import cn from 'classnames';

import style from './IconCard.module.scss';

const IconCard = ({ text, icon: Icon, dark, className }) => {
  return (
    <div className={cn(style.container, { [style.dark]: dark }, className)}>
      <Icon className={style.icon} />
      <p className={cn(style.text, { [style.dark]: dark })}>{text}</p>
    </div>
  );
};

export default IconCard;
