import React from 'react';

import join from 'assets/img/join.jpg';
import joinWebP from 'assets/img/join.webp';
import join2x from 'assets/img/join-2x.webp';
import { Footer, Header, ImageWithList, SeoHelmet } from 'components';

import { CONTACT_EMAIL } from '../../constants/misc';
import pages from '../../dictionaries/pages.json';
import OurVision from './sections/OurVision';
import TopSection from './sections/TopSection';

import style from './About.module.scss';

const data = {
  darkBg: true,
  imageOnLeft: true,
  image: join,
  sources: [
    { src: joinWebP, size: '1x', type: 'image/webp' },
    { src: join2x, size: '2x', type: 'image/webp' },
  ],
  imageAlt: 'woman',
  subtitle: 'Join our team',
  title: 'Are you ready to transform the healthcare industry?',
  button: {
    label: 'Get in touch',
    link: '/contact'
  },
  content: [
    <>
      Join us in revolutionizing healthcare processes. <br />
      We are reshaping the healthcare ecosystem through technology, everything is 100% digital with us.
    </>,
    <>
      Sounds interesting? Reach us out at <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
    </>
  ]
};

const About = () => {
  return (
    <>
      <SeoHelmet title={pages.about.title}/>
      <div className={style.container}>
        <Header />
        <TopSection />
        <OurVision />
        <ImageWithList section={data} />
        <Footer />
      </div>
    </>
  );
};

export default About;
