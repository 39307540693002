import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import cn from 'classnames';

import { ReactComponent as Logo } from 'assets/img/avalio-logo.svg';
import { Button } from 'components';

import style from './Header.module.scss';

const Header = ({ transparent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const headerRef = useRef();

  const onClick = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(document.body);
      document.body.classList.add(style.noScroll);
    } else {
      enableBodyScroll(document.body);
      document.body.classList.remove(style.noScroll);
    }
  }, [headerRef, isOpen]);

  useEffect(() => {
    return () => {
      enableBodyScroll(document.body);
      document.body.classList.remove(style.noScroll);
    };
  }, []);

  const loginButtonHref = import.meta.env.VITE_NODE_ENV === 'development' ? 'http://localhost:2137/' :'https://app.avalio.io/login'

  return (
    <header className={cn(style.container, { [style.transparent]: transparent })} ref={headerRef}>
      <Link to={'/'} onClick={onClick} className={style.topElement}>
        <Logo className={style.logo} />
      </Link>
      <nav className={cn(style.nav, { [style.isOpen]: isOpen })}>
        <NavLink
          to={'/'}
          exact
          className={style.link}
          activeClassName={style.active}
          onClick={onClick}
        >
          Home
        </NavLink>
        <NavLink
          to={'/benefits'}
          className={style.link}
          activeClassName={style.active}
          onClick={onClick}
        >
          Benefits
        </NavLink>
        <NavLink
          to={'/about'}
          className={style.link}
          activeClassName={style.active}
          onClick={onClick}
        >
          About
        </NavLink>
        <NavLink
          to={'/contact'}
          className={style.link}
          activeClassName={style.active}
          onClick={onClick}
        >
          Contact
        </NavLink>
        <a href={loginButtonHref} onClick={onClick} style={{ textDecoration: 'none'}}>
          <Button
            label={'Log in'}
            className={style.loginButton}
            empty
          />
        </a>
        <Button
          label={'Schedule a demo'}
          to={'/contact'}
        />
      </nav>
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className={cn(style.mobileMenuButton, {
          [style.buttonActive]: isOpen
        }, style.topElement)}
      >
        <span className={style.content} />
      </button>
    </header>
  );
};

export default Header;
