import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ReactComponent as Ambulatory } from 'assets/icons/ambu.svg';
import { ReactComponent as Clinic } from 'assets/icons/clinic.svg';
import { ReactComponent as Dental } from 'assets/icons/dental.svg';
import { ReactComponent as Hospital } from 'assets/icons/hospital.svg';
import { ReactComponent as Lab } from 'assets/icons/lab.svg';
import { ReactComponent as Nursing } from 'assets/icons/nursing.svg';
import { ReactComponent as Rehab } from 'assets/icons/rehab.svg';
import { ReactComponent as Vet } from 'assets/icons/vet.svg';
import { IconCard, useIsMobile } from 'components';

import 'swiper/css';
import 'swiper/css/pagination';

import style from './OurServices.module.scss';

const data = [
  { text: 'Hospitals', icon: Hospital },
  { text: 'Dental Clinics', icon: Dental },
  { text: 'Veterinary Clinics', icon: Vet },
  { text: 'Nursing Homes', icon: Nursing },
  { text: 'Aesthetic Clinics', icon: Clinic },
  { text: 'Medical Laboratories', icon: Lab },
  { text: 'Rehabilitation Centers', icon: Rehab },
  { text: 'Ambulatory Surgery Centers', icon: Ambulatory }
];

const OurServices = () => {
  const isMediumScreen = useIsMobile(1500);
  const isMobileScreen = useIsMobile();

  return (
    <div className={style.container}>
      <h3 className={style.title}>Our services are dedicated to:</h3>
      <div className={style.wrapper}>
        <Swiper
          slidesPerView={isMobileScreen ? 1.7 : isMediumScreen ? 4 : 6}
          initialSlide={isMobileScreen ? 0 : isMediumScreen ? 1 : 2}
          spaceBetween={isMobileScreen ? 16 : 24}
          centeredSlides
        >
          {data.map((item) => (
            <SwiperSlide key={item.text}>
              <IconCard
                text={item.text}
                icon={item.icon}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default OurServices;
