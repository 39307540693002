import React from 'react';

import { Footer, Header, JoinUsBanner, SeoHelmet } from 'components';

import pages from '../../dictionaries/pages.json';
import OurServices from './sections/OurServices';
import TopSection from './sections/TopSection';
import WhyAvalio from './sections/WhyAvalio';

import style from './Benefits.module.scss';

const Benefits = () => {
  return (
    <>
      <SeoHelmet title={pages.benefits.title}/>
      <div className={style.container}>
        <Header />
        <TopSection />
        <OurServices />
        <WhyAvalio />
        <JoinUsBanner light />
        <Footer />
      </div>
    </>
  );
};

export default Benefits;
