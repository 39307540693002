import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { ConfigProvider } from 'antd';
import pl_PL from 'antd/lib/locale/pl_PL';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

import AppRouter from './app/AppRouter';
import { SeoHelmet } from './components';

// import 'react-notifications-component/dist/theme.css';
// import 'semantic-ui-css/semantic.min.css';
// import 'react-notifications-component/dist/theme.css';
import 'src/styles/reset.scss';
import 'src/styles/global.scss';
import 'src/styles/index.scss';

dayjs.extend(updateLocale);
dayjs.updateLocale('pl', {
  weekStart: 1
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ConfigProvider locale={pl_PL}>
        <SeoHelmet />
        <AppRouter />
      </ConfigProvider>
    </HelmetProvider>
  </React.StrictMode>
);

