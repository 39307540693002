import React, { useEffect, useRef } from 'react';
import cn from 'classnames';

import { Button } from 'components';

import style from './JoinUsBanner.module.scss';

const JoinUsBanner = ({ light = false }) => {
  const containerRef = useRef();

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrolled = window.scrollY;

      if (containerRef.current?.style?.backgroundPosition) {
        containerRef.current.style.backgroundPosition = 'top ' + scrolled * 0.1 + 'px';
      }
    });
  }, []);

  return (
    <div
      className={cn(style.container, { [style.light]: light })}
      ref={containerRef}
    >
      <h3 className={style.text}>
        Join us in the journey <br />
        to redefine healthcare procurement.
      </h3>
      <Button
        label={'Schedule a Demo'}
        to={'/contact'}
      />
    </div>
  );
};

export default JoinUsBanner;
