import errorPlaceholder from 'src/assets/img/product-thumbnail.png';

export const getNotificationIdFromName = (errors) => {
  const arr = [];

  if (typeof errors?.length !== 'number') {
    return 'error';
  }

  for (const element of errors) {
    arr.push(...element.split(''));
  }

  let sum = 1;
  arr.forEach((char) => (sum *= char.charCodeAt(0)));

  if (sum === Infinity) {
    sum = Math.random() * 1000000;
  } else {
    while (sum >= 1000000) {
      sum = sum / 2;
    }
  }

  return String(sum.toFixed(0));
};

export const isValidNip = (nip) => {
  if (typeof nip !== 'string') {
    return false;
  }

  nip = nip.replace(/[ \-]/gi, '');

  let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  let controlNumber = parseInt(nip.substring(9, 10));
  let weightCount = weight.length;
  for (let i = 0; i < weightCount; i++) {
    sum += parseInt(nip.substr(i, 1)) * weight[i];
  }

  return sum % 11 === controlNumber;
};

export const imageErrorHandler = (event) => {
  event.currentTarget.onerror = null;
  event.currentTarget.src = errorPlaceholder;
};
