import { useReducer, useRef } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import SimpleReactValidator from 'simple-react-validator';

import { isValidNip } from '../../utils/helpers';

const useValidator = (
  options = {
    messages: {},
    validators: {}
  }
) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const validator = useRef(
    new SimpleReactValidator({
      ...options,
      messages: {
        email: 'Adres e-mail nie jest poprawny',
        integer: 'To pole musi być liczbą całkowitą',
        max: 'Liczba ta nie może przekroczyć :max:type',
        min: 'Liczba ta nie może być mniejsza niż :min:type',
        numeric: 'To pole musi być liczbą',
        accepted: 'To pole musi zostać zaakceptowane',
        required: 'This field is required',
        in: 'Hasła muszą się zgadzać',
        not_in: 'Podana wartość jest już zajęta',
        ...(options.messages || {})
      },
      autoForceUpdate: { forceUpdate },
      validators: {
        min_password: {
          message: 'Hasło musi mieć 8 znaków',
          rule: (val) => val && val.length >= 8
        },
        correct_url: {
          message: 'To pole musi być poprawnym linkiem URL',
          rule: (val) =>
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_.~#?&\/=]*)/.test(val)
        },
        min_price: {
          message: 'Min. cena to 0,01zł',
          rule: (val) => +val >= 0.01
        },
        not_negative: {
          message: 'To pole nie może być ujemne',
          rule: (val) => +val >= 0
        },
        only_positive: {
          message: 'Wartość musi być dodatnia',
          rule: (val) => +val > 0
        },
        phone: {
          message: 'Podaj poprawny numer telefonu',
          rule: (val = '') => isValidPhoneNumber(val)
        },
        nip: {
          message: 'Podaj poprawny numer NIP',
          rule: (val) => isValidNip(val)
        },
        product_name: {
          message: 'Nazwa zawiera niedozwolony znak',
          rule: (val) => !/[;\*]/.test(val)
        }
      }
    })
  );

  return validator.current;
};

export default useValidator;
