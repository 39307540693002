import React from 'react';

import style from './Solutions.module.scss';

const data = ['Inventory management', 'Supplier management', 'Three-way invoice matching'];

const Solutions = () => {
  return (
    <section className={style.container}>
      <h5 className={style.text}>Avalio is your one-stop solution for all of the expenses your clinic has:</h5>
      <div className={style.wrapper}>
        {data.map((item, index) => (
          <div
            className={style.card}
            key={item}
          >
            <span className={style.counter}>
              <span className={style.count}>{index + 1}</span>
            </span>
            <p className={style.title}>{item}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Solutions;
