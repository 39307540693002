import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import InputWrapper, { getWrapperProps } from 'components/layout/InputWrapper';

import style from './Input.module.scss';

const Input = React.forwardRef((props, ref) => {
  const { wrapperClass, placeholder, type = 'text', wrapperStyle, extraError, ...rest } = props;
  const [showError, setShowError] = useState(false);
  const wrapperProps = getWrapperProps({ ...props, extraError });


  useEffect(() => {
    setShowError(!!extraError);
  }, [extraError]);

  return (
    <InputWrapper
      {...wrapperProps}
      setShowError={setShowError}
      showError={showError}
      extraError={extraError}
    >
      <div className={classNames(style.inputWrapper, wrapperClass)}>
        <input
          ref={ref}
          type={type}
          className={classNames(style.input, {
            [style.error]: Boolean(wrapperProps.errorMessage),
            [style.disabled]: props.disabled
          })}
          placeholder={placeholder}
          {...rest}
        />
      </div>
    </InputWrapper>
  );
});

export default Input;
