import React from 'react';

import { Button } from 'components';

import style from './TopSection.module.scss';

const TopSection = () => {
  return (
    <div className={style.container}>
      <div className={style.content}>
        <h1 className={style.title}>
          Make your procurement <br />
          smarter, serve your <br />
          patients better
        </h1>
        <p className={style.text}>
          See how our software helps upkeep key processes and reallocate more resources towards patient care.
        </p>
        <Button
          label={'Schedule a Demo'}
          to={'/contact'}
        />
      </div>
    </div>
  );
};

export default TopSection;
