import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ReactNotifications } from 'react-notifications-component';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { ErrorFallback,ScrollToTop } from 'components';

import LandingPage from 'src/features/LandingPage';
import NotFoundRoute from 'src/features/NotFoundRoute';
import PrivacyPolicy from 'src/features/PrivacyPolicy';
import Statute from 'src/features/Statute';

//pages
import About from '../../features/About';
import Benefits from '../../features/Benefits';
import ContactPage from '../../features/ContactPage';


const AppRouter = () => {

  return (
    <Router>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ScrollToTop />
        <ReactNotifications />
        <Switch>
          <Route
            exact
            path='/'
            component={LandingPage}
          />
          <Route
            exact
            path='/benefits'
            component={Benefits}
          />
          <Route
            exact
            path='/about'
            component={About}
          />
          <Route
            exact
            path='/contact'
            component={ContactPage}
          />
          <Route
            path='/privacy-policy'
            component={PrivacyPolicy}
          />
          <Route
            path='/statute'
            component={Statute}
          />
          <Route
            path='/404'
            component={NotFoundRoute}
          />
          <Route render={() => <Redirect to='/404' />} />
        </Switch>
      </ErrorBoundary>
    </Router>
  );
};

export default AppRouter;
