import { Footer, Header, SeoHelmet } from 'components';

import pages from 'src/dictionaries/pages.json';

import Main from './components/Main';

const ContactPage = () => {
  return (
    <>
      <SeoHelmet
        title={pages.contact.title}
        description={pages.contact.description}
      />
      <Header />
      <Main />
      <Footer />
    </>
  );
};

export default ContactPage;
