import React from 'react';

import { ReactComponent as GrowIcon } from 'assets/icons/grow.svg';
import { ReactComponent as ScreenIcon } from 'assets/icons/screen.svg';
import { ReactComponent as TicIcon } from 'assets/icons/tic.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { IconCard } from 'components';

import style from './Icons.module.scss';

const data = [
  { text: 'Implement digital workflow', icon: ScreenIcon },
  { text: 'Automate ordering with AI', icon: TicIcon },
  { text: 'Save time and money', icon: GrowIcon },
  { text: 'Improve patient experience', icon: UserIcon }
];

const Icons = () => {
  return (
    <div className={style.container}>
      {data.map((item) => (
        <IconCard
          text={item.text}
          icon={item.icon}
          key={item.text}
          dark
        />
      ))}
    </div>
  );
};

export default Icons;
