import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as TickGradient } from 'assets/icons/tick-gradient-animation.svg';
import { useIsMobile } from 'components';

import './animation.css';
import style from './OurVision.module.scss';

const OurVision = () => {
  const isMobile = useIsMobile();
  const titleRef = useRef(null);
  const [isTickVisible, setIsTickVisible] = useState(false);

  useEffect(() => {
    const windowListener = () => {
      if (titleRef.current && titleRef.current.getBoundingClientRect().top <= 350) {
        setIsTickVisible(true);
      }
    };

    if (!isTickVisible) {
      addEventListener('scroll', windowListener);
    }

    return () => {
      removeEventListener('scroll', windowListener);
    };
  }, [isTickVisible]);

  return (
    <div className={style.container}>
      <p className={style.subtitle}>Our vision</p>
      <h4
        className={style.title}
        ref={titleRef}
      >
        Numerous industries have undergone the digital transformation in recent decades, yet the
        healthcare industry is characterized by offline and inefficient manual processes.
      </h4>
      <div className={style.wrapper}>
        <div className={style.tickWrapper}>{(isMobile || isTickVisible) && <TickGradient className={style.tick} />}</div>
        <div className={style.box}>
          <p className={style.text}>
            Our vision is to digitize the healthcare industry and introduce transparency and reliability in the healthcare supply
            chain. We want to help you focus on treating patients while we assist with all your back office processes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurVision;
