import { CONTACT_EMAIL } from '../../../../constants/misc';
import Form from '../Form';

import style from './Main.module.scss';

const Main = () => {
  return (
    <div className={style.container}>
      <div className={style.left}>
        <p className={style.subtitle}>Contact us</p>
        <h1 className={style.title}>Stay in touch</h1>
        <p className={style.text}>
          Plac Unii, 2 Puławska Str., Building B, 3rd floor <br />
          02-566 Warszawa
        </p>
        <a
          className={style.link}
          href={`mailto:${CONTACT_EMAIL}`}
        >
          {CONTACT_EMAIL}
        </a>
      </div>
      <Form />
    </div>
  );
};

export default Main;
