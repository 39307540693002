import { useState } from 'react';

import {  Button, Card, Input, PhoneInput, Textarea, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { LandingApi } from 'src/api';

import style from './Form.module.scss';

export default function Form() {
  const validator = useValidator();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    try {
      setIsLoading(true);
      await LandingApi.contactUs({
        name: name,
        email: email,
        phone_number: phone,
        company: company,
        body: message
      });
      notifyCommon(['Message was send correctly']);
      validator.hideMessages();
      setName('');
      setEmail('');
      setPhone('');
      setCompany('');
      setMessage('');
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card>
      <form onSubmit={submitHandler}>
        <div className={style.inputs}>
          <Input
            value={name}
            onChange={(v) => setName(v.target.value)}
            label={'Name'}
            wrapperStyle={style.input}
            validator={validator}
            rule={'required|string'}
          />
          <Input
            value={email}
            type='email'
            onChange={(v) => setEmail(v.target.value)}
            label={'E-mail'}
            wrapperStyle={style.input}
            validator={validator}
            rule={'required|email'}
          />
          <PhoneInput
            label={'Phone number'}
            value={phone}
            onChange={setPhone}
            wrapperStyle={style.input}
            validator={validator}
            rule={'required|phone'}
          />
          <Input
            value={company}
            onChange={(v) => setCompany(v.target.value)}
            label={'Company name'}
            wrapperStyle={style.input}
            validator={validator}
          />
          <Textarea
            label={'Message'}
            value={message}
            onChange={(v) => setMessage(v.target.value)}
          />
        </div>
        <Button
          label={'Submit'}
          type={'submit'}
          className={style.button}
          isLoading={isLoading}
        />
      </form>
    </Card>
  );
}
