import React from 'react';

import phone from 'assets/img/phone-in-hand.png';

import style from './TickSection.module.scss';

const TickSection = () => {
  return (
    <div className={style.container}>
      <img
        src={phone}
        className={style.phoneImage}
        alt={'Phone with Avalio logo held in hand'}
      />
      <div className={style.wrapper}>
        <h2 className={style.title}>Avalio is your one-stop digital solution for all the expenses</h2>
        <p className={style.text}>
          Join us in revolutionizing healthcare processes. <br />
          We use the technology to redefine the healthcare landscape.
        </p>
        <ul className={style.list}>
          <li className={style.listItem}>From Order To Payment</li>
          <li className={style.listItem}>For Small Practices and Big Organizations</li>
        </ul>
      </div>
    </div>
  );
};

export default TickSection;
